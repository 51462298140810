
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


// import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
// import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  profile = null;
  parametrsBank = null;
  selectedParametrs = null;

  // setCookie(name, value, expires) {
  //   let cookie = name + "=" + value + ";";
  
  //   if (expires) {
  //     // If it's a date
  //     if(expires instanceof Date) {
  //       // If it isn't a valid date
  //       if (isNaN(expires.getTime()))
  //        expires = new Date();
  //     }
  //     else
  //       expires = new Date(new Date().getTime() + parseInt(expires) * 1000 * 60 * 60 * 24);
  
  //     cookie += "expires=" + expires.toGMTString() + ";";
  //   }
  
  //   document.cookie = cookie;
  // }

  setCookie(name,value) {
    // const date = new Date();
    //       date.setTime(date.getTime()+(days*24*60*60*1000));
    // const expires = "; expires=" + date.toUTCString();

    // document.cookie = name + "=" + value+expires+"; path=/";
    localStorage.setItem(name, value);
}

  getCookie(name) {
    // const regexp = new RegExp("(?:^" + name + "|;\s*"+ name + ")=(.*?)(?:;|$)", "g");
    // const result = regexp.exec(document.cookie);
    // return (result === null) ? null : result[1];
    return localStorage.getItem(name);
  }

  removeCookie(name) {
    localStorage.removeItem(name);
  }

  get(h: string, data: string = '') {
    const token = localStorage.getItem('token');
    return this.http.get('https://binompro.com/api/json.php?api='+ h +'&token=' + token  + data)
    .pipe(map(results => results));
  }

  post(h: string, data: any) {
    const token = localStorage.getItem('token');
    return this.http.post(''+ h +'/' + token, data)
    .pipe(map(results => results))
  };

  put(h: string, data) {
    const token = localStorage.getItem('token');
    return this.http.put(''+ h +'/' + token, {data: data})
    .pipe(map(results => results))
  };

  videoSrc(id) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+ id +'??enablejsapi=0&autoplay=0&start=0&autohide=1&wmode=opaque&showinfo=0&fs=1&rel=0');
  }

  convertImgToBase64URL(url, callback){
    let canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d'),
    img = new Image;
    img.crossOrigin = 'Anonymous';
    img.onload = function(){
        let dataURL;
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL("image/png");
        callback(dataURL);
        canvas = null; 
    };
    img.src = url;
  }

}
